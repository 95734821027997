<template>
  <div class="inner-container fad download-banner">
    <div class="download-banner__text">
      <div class="download-banner__text-padding">
        <div class="download-banner__bg" />
        <h2 class="h1">
          {{ title }}
        </h2>
        <main-button
          :label="cta"
          :link="downloadAppLink"
          tag="a"
          target="_blank"
          icon-position="RIGHT"
          class-variant="btn-blue full-width-sm"
          @click="trackDownloadLink"
        >
          <icon-arrow-right />
        </main-button>
      </div>
    </div>
    <div v-if="image?.filename" class="download-banner__img">
      <ImageLazy :src="`${image.filename}`" :alt="`${$t('appDownload.title')}`" :title="`${$t('appDownload.title')}`" />
    </div>
  </div>
</template>

<script>
import DownloadApp from '@/mixins/download-app-mixin'
import MainButton from '@/components/Buttons/MainButton/MainButton'
import IconArrowRight from '@/icons/IconArrowRight'

export default {
  name: 'SbDownloadBanner',
  components: {
    MainButton,
    IconArrowRight,
  },
  mixins: [DownloadApp],
  props: {
    title: { type: String, required: true },
    cta: { type: String, required: true },
    image: { type: Object, required: true },
  },
}
</script>

<style lang="scss">
.download-banner {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @include mq($mq-sm) {
    display: block;
  }

  & > * {
    flex: 1 1 0px;
    margin-right: 20px;

    @include mq($mq-sm) {
      margin-right: 0;
    }
  }

  &__text {
    border-radius: 20px;
    position: relative;

    h2 {
      margin-bottom: 20px;
    }
  }

  &__text-padding {
    padding: 30px;
    padding-right: 0;

    @include mq($mq-sm) {
      padding: 30px;
    }

    @include mq($mq-xs) {
      padding: 20px;
    }

    & > * {
      position: relative;
    }
  }

  &__img {
    position: relative;

    @include mq($mq-sm) {
      display: none;
    }

    img {
      display: block;
      max-width: 100%;
    }
  }

  &__bg {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: calc(200% + 40px);
    z-index: 0;
    border-radius: 20px;
    background-color: var(--landing-color-primary);

    @include mq($mq-sm) {
      width: 100%;
    }
  }
}
</style>
